import React from 'react'
import '../../../stylesheets/base/_rootcause.scss'

function RootCauseAnalysis() {
    return (
        <div className='root_cause_container'>
            <a href="/help/root-cause-analysis.png" download>Download</a>
            <div className='root_cause_img'>
                <img src="/help/root-cause-analysis-compressed.png"/>
            </div>
            
        </div>
    )
}

export default RootCauseAnalysis
